import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import loadable from '@loadable/component';

import imageStepsFirst from '../../assets/images/illustrations/home-steps-first.png';
import imageStepsSecond from '../../assets/images/illustrations/home-steps-second.png';
import imageStepsThird from '../../assets/images/illustrations/home-steps-third.png';

import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png';
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png';
import imageTestimonialsRob1x from '../../assets/images/illustrations/rob-testimonials1x.png';
import imageTestimonialsRob2x from '../../assets/images/illustrations/rob-testimonials2x.png';
import imageTestimonialsJames1x from '../../assets/images/illustrations/james-testimonials1x.png';
import imageTestimonialsJames2x from '../../assets/images/illustrations/james-testimonials2x.png';
import imageTestimonialsLynn from '../../assets/images/illustrations/lynn-testimonials.png';

import imageSecondStepsFirst from '../../assets/images/illustrations/home-second-steps-first.png';
import imageSecondStepsSecond from '../../assets/images/illustrations/home-second-steps-second.png';
import imageSecondStepsThird from '../../assets/images/illustrations/home-second-steps-third.png';

import imageSecondSliderFirst from '../../assets/images/illustrations/home-second-slider-first.png';
import imageSecondSliderSecond from '../../assets/images/illustrations/home-second-slider-second.png';
import imageSecondSliderThird from '../../assets/images/illustrations/home-second-slider-third.png';
import imageSecondSliderFourth from '../../assets/images/illustrations/home-second-slider-fourth.png';

import imageSecondSliderFirstMobile from '../../assets/images/illustrations/home-second-slider-first-mobile.png';
import imageSecondSliderSecondMobile from '../../assets/images/illustrations/home-second-slider-second-mobile.png';
import imageSecondSliderThirdMobile from '../../assets/images/illustrations/home-second-slider-third-mobile.png';
import imageSecondSliderFourthMobile from '../../assets/images/illustrations/home-second-slider-fourth-mobile.png';

import { useMemo } from 'react';
import { useCallback } from 'react';
import useSSR from '../../hooks/useSSR';
import useSliderHeight from '../../hooks/useSliderHeight';
import useMobileNavigation from '../../hooks/useMobileNavigation';
import FirstSection from '../../components/MainPageComponents/FirstSection';

const SliderSection = loadable(() => import('../../components/CommonSections/SliderSection'));
const FullSizeSliderSection = loadable(() =>
  import('../../components/CommonSections/FullSizeSliderSection')
);
const DownloadAppSection = loadable(() =>
  import('../../components/CommonSections/DownloadAppSection')
);
const StepsSection = loadable(() => import('../../components/CommonSections/StepsSection'));
const BasicModal = loadable(() => import('../../components/Modals/BasicModal'));
const DownloadAppModal = loadable(() => import('../../components/Modals/DownloadAppModal'));
const DownloadBonusModal = loadable(() => import('../../components/Modals/DownloadBonusModal'));
const SEO = loadable(() => import('../../components/SEO'));
const SwitchingWithUsCouldSaveYou300AMonthSection = loadable(() =>
  import('../../components/MainPageComponents/SwitchMortgageWithUs')
);
const ShopWithSpriveSection = loadable(() =>
  import('../../components/MainPageComponents/ShopWithSprive')
);
const SixthSection = loadable(() => import('../../components/MainPageComponents/SixthSection'));
const FourthSection = loadable(() => import('../../components/MainPageComponents/FourthSection'));

const stepsData = [
  {
    image: imageStepsFirst,
    title: 'Automate your savings',
    description:
      'We’ll save spare cash, so you can make payments to your lender in one tap.',
  },
  {
    image: imageStepsSecond,
    title: 'Rewards from shopping',
    description:
      'Get cashback from your everyday spending to pay off your mortgage faster.',
  },
  {
    image: imageStepsThird,
    title: 'Remortgage easily',
    description:
      'We’ll automatically link to your mortgage to help you get your next deal at the right time.',
  },
];

const secondStepsData = [
  {
    image: imageSecondStepsFirst,
    title: 'Link bank account',
    description: 'Download Sprive & connect a bank account to start smart autosaving.',
  },
  {
    image: imageSecondStepsSecond,
    title: 'Set your saving limits',
    description: 'Our smart AI will save the perfect amount within limits you can afford.',
  },
  {
    image: imageSecondStepsThird,
    title: 'Mortgage-free faster',
    description: 'In one tap, make payments to your lender, or withdraw the money back.',
  },
];

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 767.98 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 991.98 : 0);

const IndexPage = ({ data, location }) => {
  const [isMobile, setMobile] = useState(false);
  const [isTablet, setTablet] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [isBonusModalOpen, setIsBonusModalOpen] = useState(false);
  const isSSR = useSSR();
  const sliderHeight = useSliderHeight('447px', '126px', '144px');

  const handleResize = () => {
    setMobile(IS_MOBILE());
    setTablet(IS_TABLET());
  };

  useEffect(() => {
    setMobile(IS_MOBILE());
    setTablet(IS_TABLET());
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleBonusClose = useCallback(() => {
    setIsBonusModalOpen(false);
    if (!isSSR) {
      localStorage.setItem('bonusModalState', 'close');
    }
  }, [isSSR]);

  useEffect(() => {
    if (isSSR) return;
    else if (localStorage.getItem('bonusModalState') !== 'open') return;

    setIsBonusModalOpen(true);
  }, [isSSR]);

  useEffect(() => {
    if (isMobile && firstModalOpen) {
      useMobileNavigation();
    }

    if (isMobile && isModalOpen) {
      setFirstModalOpen(true);
      setIsModalOpen(false);
    }
  }, [isMobile, isModalOpen, firstModalOpen]);

  const secondSliderItems = useMemo(
    () => [
      {
        image: isMobile || isTablet ? imageSecondSliderFirstMobile : imageSecondSliderFirst,
        title: 'Save money automatically',
        firstDescription:
          'Sprive’s AI will save money for you based on your spending, without it impacting your lifestyle.',
        secondDescription:
          'Set your saving limits, and let the app do the rest.',
      },
      {
        image: isMobile || isTablet ? imageSecondSliderSecondMobile : imageSecondSliderSecond,
        title: 'Pay off your mortgage faster',
        firstDescription:
          'You could save you thousands in interest from making mortgage overpayments.',
        secondDescription: 'Make one-tap overpayments straight to your lender from autosaving spare cash, rewards earned or manual payments.',
      },
      {
        image: isMobile || isTablet ? imageSecondSliderThirdMobile : imageSecondSliderThird,
        title: (
          <>
            Track your progress
          </>
        ),
        firstDescription:
          'See how much interest you’re on track to save and how many years you’ve knocked off your mortgage term all in the app.',
        secondDescription:
          'You’ll be amazed at how much you can save on your mortgage by making small regular overpayments.',
      },
      {
        image: isMobile || isTablet ? imageSecondSliderFourthMobile : imageSecondSliderFourth,
        title: 'How much of your home do you own?',
        firstDescription:
          'See how much of your home you own and its estimated value all in the app.',
        secondDescription:
          'The more you own, the more chance you’ll have to unlock cheaper rates when you remortgage.',
      },
    ],
    [isMobile, isTablet]
  );

  const sliderItems = useMemo(
    () => [
      {
        image: isMobile ? imageTestimonialsIndi1x : imageTestimonialsIndi2x,
        description: `Thanks to Sprive I was able to both easily manage repayments,
        but also find great deals for remortgaging, which Sprive took
        the hassle out of too.`,
        person: 'Indi Rai',
        personInfo: 'Head of Marketing',
      },
      {
        image: isMobile ? imageTestimonialsRob1x : imageTestimonialsRob2x,
        description: `Sprive has changed the way I think about my mortgage.
        Being able to make payments to my lender in one-tap makes the app a no-brainer.`,
        person: 'Rob May',
        personInfo: 'Financial Advisor',
      },
      {
        image: imageTestimonialsLynn,
        description: `Sprive has been a game changer for me. I had never overpaid my mortgage
        before Sprive, and after a year I had overpaid by £600.`,
        person: 'Lynn Beattie',
        personInfo: 'Managing Director',
      },
      {
        image: isMobile ? imageTestimonialsJames1x : imageTestimonialsJames2x,
        description: `Great to slowly chip away at the mortgage and see the impacts in the app.
      I have found the rewards and cashback feature very easy and super quick.`,
        person: 'James Cheung',
        personInfo: 'Business Analyst',
      },
    ],
    [isMobile]
  );

  return (
    <>
      <SEO title="" description="" />
      <div className="common-page home-page">
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '836px' : '660px' }}>
          <FirstSection setIsModalOpen={setIsModalOpen} isMobile={isMobile} />
        </div>
        <StepsSection
          title={<div className="steps-section-header">How can Sprive help you pay off {!isMobile && <br/>}your mortgage faster?</div>}
          stepsData={stepsData}
          background={isTablet ? '#EEF5FF' : '#fff'}
          isLeft
        />
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '650px' : '447' }}>
          <SliderSection sliderItems={sliderItems} customHeight={sliderHeight} />
        </div>
        <div className="how-it-works">
          <StepsSection
            title={<div className="steps-section-header">How to make {isMobile && 'one tap'} overpayments {isMobile && <br/>}with Sprive</div>}
            stepsData={secondStepsData}
            background="#fff"
            isLeft
          />
        </div>
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '751px' : '548px', height: isMobile ? '751px' : '' }}>
          <FullSizeSliderSection sliderItems={secondSliderItems} />
        </div>
        <div
          style={{
            minWidth: '100vw',
            [isMobile ? 'height' : 'minHeight']: isMobile ? '1098px' : '995px',
            overflow: 'hidden',
          }}
        >
          <SwitchingWithUsCouldSaveYou300AMonthSection isMobile={isMobile} isTablet={isTablet} />
        </div>
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '445px' : '720px' }}>
          <ShopWithSpriveSection isMobile={isMobile} />
        </div>
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '312px' : '486px' }}>
          <SixthSection isMobile={isMobile} />
        </div>
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '860px' : '602' }}>
          <FourthSection isMobile={isMobile} />
        </div>
        <div style={{ minWidth: '100vw', minHeight: isMobile ? '382px' : '315px' }}>
          <DownloadAppSection />
        </div>
      </div>
      <BasicModal
        isOpen={!isMobile && isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
        style={{
          content: {
            height: isMobile ? '272px' : '557px',
          },
        }}
      >
        <DownloadAppModal />
      </BasicModal>
      <BasicModal
        isOpen={isBonusModalOpen}
        closeHandler={handleBonusClose}
        isBottom={isMobile}
        style={{
          content: {
            height: isMobile ? '540px' : '650px',
          },
        }}
      >
        <DownloadBonusModal />
      </BasicModal>
    </>
  );
};

export const query = graphql`{
  imageIllustrationMainBanner: file(
    relativePath: {eq: "illustrations/Main banner.png"}
  ) {
    childImageSharp {
      gatsbyImageData(height: 560, layout: FULL_WIDTH)
    }
  }
}`;

export default IndexPage;
