import React from 'react';

import imageFirstSectionMain from '../../assets/images/illustrations/home-page-header.png';
import imageFirstSectionMainMobile from '../../assets/images/illustrations/home-page-header-mobile.png';

import imagePartners from '../../assets/images/partners/partners-desktop.png';
import imagePartnersMobile from '../../assets/images/partners/partners-mobile.png';

import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import { SecondaryTitle } from '../../components/Typography';
import CustomImage from '../../components/Image/Image';

const FirstSection = ({ setIsModalOpen, isMobile }) => {
  return (
    <div>
      <section className="first-section">
        <SectionWrapper isFirstSection>
          <CustomHeadingSection
            leftContent={
              <HeaderLeftContent
                title={<>Mortgage Free, Faster</>}
                description={
                  <>
                    Find spare cash and earn rewards from everyday shopping, so you can save thousands in interest {!isMobile && <br/>}and take years off your mortgage.
                  </>
                }
                buttonText="Get the app"
                imageReversed
                handleClick={() => setIsModalOpen(true)}
                image={isMobile || isMobile === undefined ? imageFirstSectionMainMobile : null}
                noPadding
                useWidget
                widgetAfterImage
              />
            }
            leftSize={7}
            rightSize={5}
          />
          {!isMobile && isMobile !== undefined && (
            <div className="main-image-container">
              <CustomImage
                className="w-100 main-image"
                src={imageFirstSectionMain}
                alt="main image"
              />
            </div>
          )}
        </SectionWrapper>
      </section>
      <SectionWrapper>
        <div className="bottom__logos__wrapper">
          <div className="bottom__logos__title">
            <SecondaryTitle>Featured in</SecondaryTitle>
          </div>
          <div className="bottom__logos__images">
            <CustomImage
              src={isMobile || isMobile === undefined ? imagePartnersMobile : imagePartners}
            />
          </div>
        </div>
      </SectionWrapper>
    </div>
  );
};

export default FirstSection;
